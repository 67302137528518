/** @jsx jsx */
import { jsx } from '@emotion/react'
import { css } from '@emotion/react'

const style = css`
  display: flex !important;

  &:hover {
    cursor: pointer;
    path {
      fill: #33535E;
    }
  }
`

const FacebookIcon = () => {
  return (
    <a css={style} href='https://www.facebook.com/bert.schools/' target="_blank">
      <svg
        css={style}
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
      >
        <path
          d='M10.4324 19.8213H13.1869V12.5995H15.1053L15.3127 10.1833H13.1869V8.80543C13.1869 8.23529 13.2971 8.01131 13.822 8.01131H15.3127V5.5H13.4072C11.3592 5.5 10.4324 6.44344 10.4324 8.25566V10.1833H9V12.6335H10.4324V19.8213Z'
          fill='#312F2D'
        />
      </svg>
    </a>
  )
}

export default FacebookIcon
