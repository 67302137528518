/** @jsx jsx */
import React, { useState, useEffect, useContext } from 'react'
import { LogoImage, NavLinks } from '@/data'
import { Col, Container, Row } from 'react-bootstrap'
import { SearchContext } from '@/context/search-context'
import { MenuContext } from '@/context/menu-context'
import { Link } from 'gatsby'
import { jsx } from '@emotion/react'
import logoBert from '@/images/lp/logotipo-bert.svg'

import {
  header,
  logo,
  mainMenu,
  submenu,
  navigator,
  socialCol,
  navLinksStyle,
} from '../assets/styles/Header.styles'
import InstagramIcon from './instagram-icon'
import FacebookIcon from './facebook-icon'
import YoutubeIcon from './youtube-icon'

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false)
  const { searchStatus, updateSearchStatus } = useContext(SearchContext)
  const { menuStatus, updateMenuStatus } = useContext(MenuContext)
  const handleSearchClick = (e) => {
    e.preventDefault()
    updateSearchStatus(!searchStatus)
  }
  const handleMenuClick = (e) => {
    e.preventDefault()
    updateMenuStatus(!menuStatus)
  }

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true)
    } else if (window.scrollY < 70) {
      setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sticky])

  return (
    <header
      css={header}
      className={`${true === sticky ? 'fixedHeader animated flipInX' : null}`}
      id='header'
    >
      <Container className='customContainer'>
        <Row className='customRow'>
          <Col className='col-6 col-logo' lg={2} md={3} sm={3}>
            <div css={logo}>
              <Link to='/'>
                <img
                  src={logoBert}
                  alt='BERT International Schools'
                  title='BERT International Schools'
                />
              </Link>
            </div>
          </Col>
          <Col lg={6} sm={8} md={6} className='col-nav'>
            <nav css={mainMenu} className='text-center'>
              <ul className='main-ul'>
                {NavLinks.map((links, index) => {
                  return (
                    <>
                    <li
                      css={navLinksStyle}
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? 'menu-item-has-children'
                          : ''
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul css={submenu} className='sub-menu'>
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      <img style={{marginLeft: '5px', paddingTop: '4px'}} src={links.icon ? links.icon : ''} alt="" />
                    </li>
                    </>
                  )
                })}
              </ul>
            </nav>
          </Col>
          <Col lg={4} md={4} sm={4} className='col-6 col-btn'>
            <div css={navigator} className='text-right'>
              <div className='social-media-icons'>
                <InstagramIcon/>
                <FacebookIcon/>
                {/* <YoutubeIcon/> */}
              </div>
              <div css={socialCol}>
                <a href='/#contatos'>
                  AGENDE UMA VISITA{' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                  >
                    <g clip-path='url(#clip0_984_545)'>
                      <path
                        d='M6.62904 1.54102L11.8371 6.74903C11.984 6.89598 11.984 7.13259 11.8371 7.27705L6.62904 12.4851'
                        stroke='#312F2D'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                      <path
                        d='M11.375 7L1.75 7'
                        stroke='#312F2D'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='square'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_984_545'>
                        <rect
                          width='14'
                          height='14'
                          fill='white'
                          transform='translate(14) rotate(90)'
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
              <a
                href='#'
                className='menu mobilemenu d-none d-md-none d-lg-none'
              >
                <i className='mei-menu'></i>
              </a>
              <a
                id='open-overlay-nav'
                className='menu hamburger'
                onClick={handleMenuClick}
                href='#'
              >
                <i className='mei-menu'></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default HeaderOne
