/** @jsx jsx */
import { jsx } from '@emotion/react'
import { css } from '@emotion/react'

const style = css`
  display: flex !important;

  &:hover {
    cursor: pointer;
    path:first-of-type {
      fill: #33535E;
    }
  }
`

const YoutubeIcon = () => {
  return (
    <a css={style} href=''>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='23'
        height='17'
        viewBox='0 0 23 17'
        fill='none'
      >
        <path
          d='M21.7952 2.96075C21.5608 2.02332 20.7992 1.26166 19.8032 0.968714C18.0455 0.5 11.132 0.5 11.132 0.5C11.132 0.5 4.15984 0.5 2.40216 0.968714C1.46473 1.20307 0.703071 1.96473 0.468714 2.96075C0 4.71843 0 8.35096 0 8.35096C0 8.35096 0 11.9835 0.468714 13.7412C0.703071 14.6786 1.46473 15.4403 2.46075 15.7332C4.21843 16.2019 11.132 16.2019 11.132 16.2019C11.132 16.2019 18.1041 16.2019 19.8032 15.7332C20.7406 15.4988 21.5023 14.7372 21.7952 13.7412C22.2639 11.9835 22.2639 8.35096 22.2639 8.35096C22.2639 8.35096 22.2639 4.71843 21.7952 2.96075Z'
          fill='#312F2D'
        />
        <path
          d='M8.84766 11.6323V5.01172L14.648 8.35131L8.84766 11.6323Z'
          fill='#EFEFEF'
        />
      </svg>
    </a>
  )
}

export default YoutubeIcon
