/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import EnvioRealizado from "@/images/lp/home/envio-realizado.svg"

import { 
  contentPage,
}  from '../assets/styles/Other.styles'

const EssencialDiferencial = () => {
  return (
    <Fragment>
      <section css={contentPage}>
        <Container>
          <Row>
            <Col lg={7} md={7} sm={12}>
              <h2>OBRIGADO PELO SEU INTERESSE</h2>
              <p>Nosso time comercial entrará em contato com você</p>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <img src={EnvioRealizado} alt='/' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default EssencialDiferencial;
